<template>
  <svg
    class="page-transition-bubble"
    width="100%"
    height="100%"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
  >
    <path
      ref="elPath"
      class="_path"
      vector-effect="non-scaling-stroke"
      d="M 0 100 V 100 Q 50 100 100 100 V 100 z"
    />
  </svg>
</template>

<script lang="ts" setup>
import { gsap as __gsap } from 'gsap'

import './bubble.css'
import type { TPageTransitionExpose } from '~/components/pageTransition/pageTransition.type.js'
import { ELenisEvents } from '~/lib/lenis/lenis.type.js'

defineExpose<TPageTransitionExpose>({
  leave,
  afterLeave,
  beforeEnter,
  enter,
})

const elPath = ref()

const paths = {
  step1: {
    unfilled: 'M 0 100 V 100 Q 50 100 100 100 V 100 z',
    inBetween: {
      curve1: 'M 0 100 V 50 Q 50 0 100 50 V 100 z',
      curve2: 'M 0 100 V 50 Q 50 100 100 50 V 100 z',
    },
    filled: 'M 0 100 V 0 Q 50 0 100 0 V 100 z',
  },
  step2: {
    filled: 'M 0 0 V 100 Q 50 100 100 100 V 0 z',
    inBetween: {
      curve1: 'M 0 0 V 50 Q 50 0 100 50 V 0 z',
      curve2: 'M 0 0 V 50 Q 50 100 100 50 V 0 z',
    },
    unfilled: 'M 0 0 V 0 Q 50 0 100 0 V 0 z',
  },
}

let isAnimating = false

function leave(callback: () => void) {
  if (isAnimating) return
  isAnimating = true

  // Lock scroll
  document.dispatchEvent(new CustomEvent(ELenisEvents.SCROLL_LOCK))

  // Anim
  __gsap
    .timeline({
      onComplete: () => {
        isAnimating = false
      },
    })
    .set(elPath.value, {
      attr: { d: paths.step1.unfilled },
    })
    .to(
      elPath.value,
      {
        duration: 0.8,
        ease: 'power4.in',
        attr: { d: paths.step1.inBetween.curve1 },
      },
      0,
    )
    .to(elPath.value, {
      duration: 0.2,
      ease: 'power1',
      attr: { d: paths.step1.filled },
      onComplete: callback,
    })
}

function afterLeave() {
  if (isAnimating) return
  isAnimating = true
}

function beforeEnter() {
  if (isAnimating) return
  isAnimating = true
}

function enter(callback: () => void) {
  if (isAnimating) return
  isAnimating = true

  // Unlock scroll
  document.dispatchEvent(new CustomEvent(ELenisEvents.SCROLL_UNLOCK))

  // Anim
  __gsap
    .timeline({
      onComplete: () => {
        isAnimating = false
      },
    })
    .set(elPath.value, {
      attr: { d: paths.step2.filled },
    })
    .to(elPath.value, {
      duration: 0.2,
      ease: 'sine.in',
      attr: { d: paths.step2.inBetween.curve1 },
    })
    .to(elPath.value, {
      duration: 1,
      ease: 'power4',
      attr: { d: paths.step2.unfilled },
      onComplete: callback,
    })
}
</script>
